/* eslint-disable react/display-name */
import { graphql } from 'gatsby';
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import ItemSEO from '../components/ItemSEO';
import '@browniebroke/gatsby-image-gallery/dist/style.css';
import { SnipcartStockContext } from '../components/SnipcartStockContext';
import SlickSlider from '../components/SlickSlider';

const ProductLayoutStyles = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 500px) minmax(auto, 40%);
  justify-content: space-around;
  @media (max-width: 700px) {
    grid-template-columns: repeat(auto-fill, 300px);
  }
  min-height: 600px;
`;

const ProductImageContainerStyles = styled.div`
  width: 95%;
  padding-left: 10px;
`;

const RightPanelStyles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding-top: 2rem;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding-top: 12rem;
    margin-top: 100%;
  }
`;

const ProductTitleStyles = styled.h2`
  font-size: 2.5rem;
`;

const BuyButton = styled.button`
  background: var(--black);
  width: 200px;
  height: 4rem;
  :hover {
    background: var(--rose);
  }
  :disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: default;
  }
`;

function getSKUFromSizeAndColor(skus, color, size) {
  if (skus) {
    const sku = skus.filter(
      (s) => s?.color === color && s?.size?.name === size
    );
    return sku[0];
  }
  return null;
}

function formatSnipcartName(name) {
  return name.replace(/[^a-zA-Z ]/g, '').replace('  ', ' ');
}

function getSizeOptionsPricing(skus, minRetail) {
  skus.nodes.forEach((d) => {
    const retailGBPAdjustment = d.retail_gbp - minRetail;

    if (retailGBPAdjustment === 0) {
      d.cart_size = `${d.size.name}`;
    } else if (retailGBPAdjustment < 0) {
      d.cart_size = `${d.size.name}[-${Number.parseFloat(
        retailGBPAdjustment
      ).toFixed(2)}]`;
    } else {
      d.cart_size = `${d.size.name}[+${Number.parseFloat(
        retailGBPAdjustment
      ).toFixed(2)}]`;
    }
  });

  const returnSkus = [...new Set(skus.nodes.map((s) => s.cart_size))].join('|');

  return returnSkus;
}

function getStockFromSnipCartItem(snipcartItem, skus) {
  const stock = snipcartItem?.variants?.map((i) => {
    const a = {};
    a.stock = i.stock;
    i.variation?.forEach((v) => {
      if (v.name === 'Color') {
        a.color = v.option;
      } else {
        a.size = v.option;
      }
    });
    return a;
  });
  const skusWithStock = skus.nodes?.map((s) => {
    if (stock) {
      stock.map((i) => {
        if ('size' in i && 'color' in i) {
          if (s.size.name === i.size && s.color === i.color) {
            s.stock = i.stock;
          }
        } else if ('size' in i) {
          if (s.size.name === i.size) {
            s.stock = i.stock;
          }
        } else if ('color' in i) {
          if (s.color === i.color) {
            s.stock = i.stock;
          }
        }
        return s;
      });
      return s;
    }
  });

  return skusWithStock;
}

function fireFacebookPageView(item) {
  if (typeof window !== 'undefined') {
    if (window.fbq != null) {
      window.fbq('track', 'ViewContent', {
        content_type: 'product',
        content_name: item.name,
        content_id: [item.id],
      });
    }
  }
}

function fireFacebookAddToCart(parsedCartItem) {
  if (typeof window !== 'undefined') {
    if (window.fbq != null) {
      window.fbq('track', 'AddToCart', {
        content_type: 'product',
        content_name: parsedCartItem.name,
        content_id: [parsedCartItem.id],
        value: parsedCartItem.price,
        currency: 'GBP',
      });
    }
  }
}

export default function SingleItemPage({ data }) {
  const { item } = data;
  const { skus } = data;

  useEffect(() => {
    fireFacebookPageView(item);

    const { Snipcart } = window;
    if (!Snipcart) return;
    // open modal on snipcart event add item on cart
    Snipcart.events.on('item.adding', (parsedCartItem) => {
      fireFacebookAddToCart(parsedCartItem);
    });
  }, [item]);

  const snipcartItemFromContext = useContext(SnipcartStockContext);

  const snipcartItem = snipcartItemFromContext.snipcartStock.filter(
    (i) => i.id === item.invoiceID
  )[0];

  const minGBPRetail = skus.nodes
    .map((el) => el.retail_gbp)
    .reduce((acc, cur) => Math.min(acc, cur));

  // sorting sizes
  const sizesUnsorted = [...new Set(skus.nodes.map((s) => s.size.name).sort())];

  const textSizes = ['Small', 'Small / Medium', 'Medium', 'Large'];
  const found = sizesUnsorted.some((e) => textSizes.indexOf(e) >= 0);

  const sizes = found ? sizesUnsorted.reverse() : sizesUnsorted;

  const [size, setSize] = useState(sizes[0]);

  // Small|Medium|Large[+5.00] baselined on the cheapest sku
  const customCartSize = getSizeOptionsPricing(skus, minGBPRetail);

  const colors = [...new Set(skus.nodes.map((s) => s.color))];
  const [color, setColor] = useState(colors[0]);

  const images = item.imageGallery.map(({ asset }) => asset);

  const [skusWithStock, setSkusWithStock] = useState(
    getStockFromSnipCartItem(snipcartItem, skus)
  );
  const [sku, setSku] = useState(
    getSKUFromSizeAndColor(skusWithStock, color, size)
  );

  useEffect(() => {
    // merge sku objects with stock data from snipcart
    setSkusWithStock(getStockFromSnipCartItem(snipcartItem, skus));
  }, [snipcartItem, skus]);

  useEffect(() => {
    // set sku for display in UI
    setSku(getSKUFromSizeAndColor(skusWithStock, color, size));
  }, [skusWithStock, color, size]);

  const serializers = {
    types: {
      block: (props) => BlockContent.defaultSerializers.types.block(props),
      span: (props) => BlockContent.defaultSerializers.types.span(props),
    },
    marks: {
      link: ({ mark, children }) => {
        const { blank, href } = mark;
        return blank ? (
          <a
            href={href}
            target="_blank"
            rel="noreferrer noopener"
            style={{ textDecoration: 'underline' }}
          >
            {children}
          </a>
        ) : (
          <a href={href} style={{ textDecoration: 'underline' }}>
            {children}
          </a>
        );
      },
      internalLink: ({ mark, children }) => {
        const { slug = {} } = mark;
        const href = `/${slug.current}`;
        return (
          <a href={href} style={{ textDecoration: 'underline' }}>
            {children}
          </a>
        );
      },
    },
  };

  return (
    <>
      <ItemSEO
        item={item}
        price={Number.parseFloat(minGBPRetail).toFixed(2)}
        currency="GBP"
      />
      <ProductLayoutStyles>
        <ProductImageContainerStyles>
          <SlickSlider images={images} />
        </ProductImageContainerStyles>
        <RightPanelStyles>
          <ProductTitleStyles>{item.name}</ProductTitleStyles>
          <div style={{ fontSize: '18px' }}>
            <BlockContent
              blocks={item._rawBlockContentDescription}
              serializers={serializers}
            />
          </div>
          {/* Size Dropdown */}
          <div>
            <p style={{ marginBottom: '0' }}>Size</p>
            <select
              name="size"
              id="size-select"
              onChange={(e) => {
                setSize(e.target.value);
              }}
              style={{
                width: '200px',
                padding: '10px',
              }}
            >
              {sizes.map((s) => (
                <option value={s} key={s}>
                  {s}
                </option>
              ))}
            </select>
          </div>
          {/* Color Dropdown */}
          <div>
            <p style={{ marginBottom: '0' }}>Color</p>
            <select
              name="color"
              id="color-select"
              onChange={(e) => {
                setColor(e.target.value);
              }}
              style={{
                width: '200px',
                padding: '10px',
              }}
            >
              {colors.map((c) => (
                <option value={c} key={c}>
                  {c}
                </option>
              ))}
            </select>
          </div>
          {/* <BottomRowStyles> */}
          {sku && (
            <div style={{ padding: '1rem 0 1rem 0' }}>
              <h2>
                {Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'GBP',
                }).format(sku.retail_gbp)}
              </h2>
            </div>
          )}
          <div style={{ padding: '1rem 0 1rem 0', fontSize: '80%' }}>
            <BuyButton
              className="snipcart-add-item"
              data-item-id={item.invoiceID}
              data-item-price={Number.parseFloat(minGBPRetail).toFixed(2)}
              data-item-name={formatSnipcartName(item.name)}
              data-item-url={`/item/${item.slug.current}/`}
              data-item-description={item.description}
              data-item-image={item.image?.asset?.fluid?.src}
              data-item-custom1-name="Color"
              data-item-custom1-options={colors.join('|')}
              data-item-custom2-name="Size"
              data-item-custom2-options={customCartSize}
              data-item-custom2-value={size}
              disabled={sku && sku.stock ? !(sku.stock > 0) : true}
            >
              {sku && sku.stock > 0 ? `ADD TO CART` : `SOLD OUT`}
            </BuyButton>
          </div>
          {/* </BottomRowStyles> */}
        </RightPanelStyles>
      </ProductLayoutStyles>
    </>
  );
}

// this has access to the pageContext variables
// set in gatsby-node.js which is where $slug
// comes from
export const query = graphql`
  query($slug: String!) {
    item: sanityItem(slug: { current: { eq: $slug } }) {
      name
      id
      invoiceID
      image {
        asset {
          fluid(maxWidth: 500, maxHeight: 500) {
            ...GatsbySanityImageFluid
          }
          fixed(width: 1200, height: 627) {
            ...GatsbySanityImageFixed
          }
        }
      }
      imageGallery {
        asset {
          thumb: fluid(maxWidth: 270, maxHeight: 270) {
            ...GatsbySanityImageFluid
          }
          full: fluid(maxWidth: 1200, maxHeight: 1200) {
            ...GatsbySanityImageFluid
          }
        }
      }
      slug {
        current
      }
      categories {
        name
      }
      _rawBlockContentDescription: _rawPortableTextDescription
    }
    skus: allSanitySku(filter: { item: { slug: { current: { eq: $slug } } } }) {
      nodes {
        id
        sku_number
        color
        size {
          name
        }
        retail_gbp
      }
    }
  }
`;
