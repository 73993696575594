import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NavigateNext } from '@styled-icons/material/NavigateNext';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NavigateBefore } from '@styled-icons/material/NavigateBefore';

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import '../styles/react-slick.css';

import Slider from 'react-slick';
import InnerImageZoom from 'react-inner-image-zoom';

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      aria-hidden="true"
      style={{ width: 'auto', height: 'auto' }}
    >
      <NavigateNext color="black" size="32px" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      aria-hidden="true"
      style={{ width: 'auto', height: 'auto' }}
    >
      <NavigateBefore color="black" size="32px" />
    </div>
  );
}

export default function SlickSlider(data) {
  const { images } = data;
  return (
    <Slider
      {...{
        dots: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        customPaging(i) {
          // return <p>{images[i].thumb.src}</p>;
          return (
            <div style={{ width: '100px' }}>
              <a>
                <img
                  src={`${images[i].thumb.src}`}
                  alt={`${images[i].thumb.src}`}
                />
              </a>
            </div>
          );
        },
      }}
    >
      {images &&
        images.map((i, index) => (
          <div key={index}>
            <InnerImageZoom
              src={i.thumb.src}
              zoomSrc={i.full.src}
              srcSet={i.thumb.srcSet}
              fullscreenOnMobile
              zoomType="hover"
            />
          </div>
        ))}
    </Slider>
  );
}
