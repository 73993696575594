import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

export default function SEO({ children, location, item, price, currency }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);
  return (
    <Helmet titleTemplate={`%s - ${site.siteMetadata.title}`}>
      <html lang="en" />
      <title>{item.name}</title>
      {/* fav icons */}
      <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      <link rel="alternate icon" href="/favicon.ico" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="utf-8" />
      <meta name="description" content={site.siteMetadata.description} />
      {/* Open Graph */}
      {location && <meta property="of:url" content={location.href} />}
      <meta
        property="og:image"
        content={item.image?.asset?.fixed?.src || '/logo.png'}
      />
      <meta property="og:title" content={item.name} key="ogtitle" />
      <meta
        property="og:site_name"
        content={site.siteMetadata.title}
        key="ogsitename"
      />
      <meta property="og:description" content={item.name} key="ogdesc" />
      <meta
        property="og:url"
        content={`${site.siteMetadata.siteUrl}/item/${item.slug.current}`}
      />
      <meta property="product:brand" content={site.siteMetadata.title} />
      <meta property="product:availability" content="in stock" />
      <meta property="product:condition" content="new" />
      <meta property="product:price:amount" content={price} />
      <meta property="product:price:currency" content={currency} />
      <meta property="product:retailer_item_id" content={item.id} />
      <meta
        property="product:item_group_id"
        content={item.categories[0]?.name}
      />
      {/* React Slick */}
      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {children}
      {/* react-animated-css */}
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.css"
      />
    </Helmet>
  );
}
